import { observer } from 'mobx-react'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { districts } from './constantsDistricts'
import arrow from '../../assets/img/arrow.svg'
import intl from 'react-intl-universal'
import { useHistory } from 'react-router-dom'

import './index.css'

interface PropsCoverDistrict {
    centerDistrict: (pos: { lat: number; lng: number }) => void
    setZoom: (zoom: number) => void
}

export const CoverDistrict = observer((props: PropsCoverDistrict) => {
    let { area } = useParams<{ area: string }>()

    const [openDistricts, setOpenDistricts] = useState<boolean>(window.innerWidth > 767)
    const [openCity, setOpenCity] = useState<boolean>(true)
    const [openCountry, setOpenCountry] = useState<boolean>(true)
    const history = useHistory()

    useEffect(() => {
        if (area) {
            const district = districts.find(({ id }) => id === area)
            if (district) changeCenter(district.polygon, district.zoom, district.id)
        }
    })

    const changeCenter = (polygon: { lat: number; lng: number }, zoom: number, id: string) => {
        props.centerDistrict(polygon)
        props.setZoom(zoom)
        if (!history.location.pathname.includes(id))
            history.push('/' + id, { shallow: true })
        if (window.innerWidth < 767) setOpenDistricts(false)
    }

    const renderDistricts = () => {
        return (
            <div className='dropDown-districts'>
                <div className='type' onClick={() => setOpenCity(!openCity)}>
                    <span>{intl.get('Запорожье')}</span>
                </div>
                {openCity &&
                    districts.map(
                        (district, index) =>
                            district.type === 'city' && (
                                <div
                                    key={`district-${index}`}
                                    onClick={()=>
                                        changeCenter(district.polygon, district.zoom, district.id)}>
                                    {intl.get(district.name)}
                                </div>
                            )
                    )}
                <div className='type' onClick={() => setOpenCountry(!openCountry)}>
                    <span>{intl.get('Область')}</span>
                </div>
                {openCountry &&
                    districts.map(
                        (district, index) =>
                            district.type === 'country' && (
                                <div
                                    key={`district-${index}`}
                                    onClick={() => 
                                        changeCenter(district.polygon, district.zoom, district.id)}>
                                    {intl.get(district.name)}
                                </div>
                            )
                    )}
            </div>
        )
    }

    const handleClickOpen = () => {
        setOpenDistricts(!openDistricts)
    }

    return (
        <div className='cover'>
            <div className='btnMenu ' onClick={handleClickOpen}>
                <span>{intl.get('Покрытие')}</span>
                <img
                    src={arrow}
                    alt='arrow'
                    style={openDistricts ? {} : { transform: 'rotate(270deg)' }}
                />
            </div>
            {openDistricts && renderDistricts()}
        </div>
    )
})
